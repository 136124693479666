import "semantic-ui-css/semantic.min.css";
import React from "react";
import { Helmet } from "react-helmet";
import { SiteData } from "../consts/SiteData";
import { Container, Divider, Header, Image } from "semantic-ui-react";
import PageLayout from "../components/PageLayout";
import { Link } from "gatsby";

const productsStyle: React.CSSProperties = {
  fontWeight: "bold",
  color: "#7c003c",
  padding: "1em",
  width: "25em",
  textAlign: "center",
};

const HomePage = () => (
  <PageLayout>
    <Helmet>
      <html lang="ja" />
      <title>{SiteData.PAGES.TOP.TITLE}</title>
    </Helmet>
    <Header
      content="ビジョンを現実に変えるITソリューション"
      as="h1"
      style={{
        width: "100%",
        height: "50vh",
        display: "flex",
        opacity: 0.9,
        backgroundImage: `url(/assets/backgroundimage.svg)`,
        backgroundSize: "cover",
        fontSize: "3em",
        fontWeight: "bold",
        verticalAlign: "middle",
        alignItems: "center",
        justifyContent: "center",
        color: "#47395e",
      }}
    ></Header>
    <Container style={{ padding: "2em 1em 0 1em" }}>
      <Header
        as="h1"
        style={{
          color: "#47395e",
        }}
      >
        Our Products
      </Header>
      <Divider />
    </Container>

    <Container
      style={{
        display: "flex",
        height: "auto",
        justifyContent: "space-around",
        flexWrap: "wrap",
      }}
    >
      <Container as={Link} to="/products/cubifox" style={productsStyle}>
        <Header as="h2" style={{ color: "#47395e" }}>
          Cubifox
        </Header>
        <Image
          src={"/assets/cubifox_trade_item_detail_image.png"}
          style={{ opacity: 0.8, height: "35vh", width: "auto" }}
          fluid
          centered
        />
        <Header as="p" style={{ color: "#47395e" }}>
          3Dモデル専用NFTマーケットプレイス
        </Header>
      </Container>
      <Container as={Link} to="/products/dddog" style={productsStyle}>
        <Header as="h2" style={{ color: "#47395e" }}>
          DDDOG
        </Header>
        <Image
          src={"/assets/dddog_item_deital_image.png"}
          style={{ opacity: 0.8, height: "35vh", width: "auto" }}
          fluid
          centered
        />
        <Header as="p" style={{ color: "#47395e" }}>
          3Dモデル生成アプリ
        </Header>
      </Container>
      <Container as={Link} to="/products/nft_login" style={productsStyle}>
        <Header as="h2" style={{ color: "#47395e" }}>
          NFT Login
        </Header>
        <Image
          src={"/assets/nft_login_image.png"}
          style={{ opacity: 0.8, height: "35vh", width: "auto" }}
          fluid
          centered
        />
        <Header as="p" style={{ color: "#47395e" }}>
          NFTの所有者を認証するOpenID Connect IDプロバイダー
        </Header>
      </Container>
    </Container>
  </PageLayout>
);

export default HomePage;
